import textResource from '../../../../utils/text-resources/rahoitu/FI/messages';
import submit from '../../../../mappers/FI/application';
import LOAN_LIMITS from '../../../../localizations/config/FI/shared/loan-limits';
import email from '../../../../validators/FI/email';
import validateTotalLoan from '../../../../validators/totalLoan';
import validateRepaymentTime from '../../../../validators/repaymentTime';
import validatePhoneNumberFi from '../../../../validators/FI/phoneNumber';

const tooltip = (text) => {
  const temp = {
    trigger: 'focus',
    text,
    width: 'fill',
    position: 'right',
  };
  return temp;
};

const data = {
  general: {
    mapper: submit,
  },
  links: {
    quickReapply: '/returning-customer/',
    quickReapplyEng: '/en/returning-customer/',
    userAgreementEng: 'https://www.sambla.se/en/user-agreement/',
    userAgreement: 'https://www.new.rahoitu.fi/kayttajaehdot/',
    dataPolicy: 'https://www.new.rahoitu.fi/tietosuojaseloste/',
    dataPolicyEng: 'https://www.sambla.se/en/data-protection-policy/',
  },
  slides: [
    {
      title: '',
      coApplicantSlide: false,
      fields: [
        {
          component: 'ValidatedForm',
          form: 'Slider',
          props: {
            label: textResource.applicationLoanAmountLabel,
            showLabel: true,
            name: 'totalAmount',
            showButtons: false,
            type: 'tel',
            lead: true,
            layout: {
              start: 1,
              end: 4,
            },
            sliderConfig: {
              range: {
                min: LOAN_LIMITS.FROM,
                max: LOAN_LIMITS.UP_TO,
              },
              step: LOAN_LIMITS.STEP,
            },
          },
        },
        {
          component: 'StoreValueDisplay',
          props: {
            value: 'totalAmount',
            fontWeight: 'bold',
            fontSize: '1rem',
            noLabel: true,
            fieldDisplay: {
              name: 'totalAmount',
              type: 'tel',
              maxValue: LOAN_LIMITS.UP_TO,
              validate: validateTotalLoan,
              validateOnRegister: true,
              suffix: textResource.loanCalculatorCurrencySuffix,
              placeholder: textResource.applicationLoanAmountPlaceholder,
              unstyled: true,
            },
            layout: {
              start: 5,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Slider',
          props: {
            name: 'repaymentTime',
            type: 'tel',
            lead: true,
            showLabel: true,
            label: textResource.repaymentTimeLabel,
            sliderConfig: {
              range: {
                min: 1,
                max: 20,
              },
            },
            layout: {
              start: 1,
              end: 4,
            },
          },
        },
        {
          component: 'StoreValueDisplay',
          props: {
            value: 'repaymentTime',
            fontWeight: 'bold',
            position: 'space-between',
            fontSize: '1rem',
            noLabel: true,
            fieldDisplay: {
              name: 'repaymentTime',
              type: 'tel',
              validate: validateRepaymentTime,
              validateOnRegister: true,
              textAlign: '',
              maxValue: 20,
              suffix: textResource.loanCalculatorRepaymentTimeSuffix,
              placeholder: textResource.repaymentTimePlaceholder,
              unstyled: true,
            },
            layout: {
              start: 5,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'EmailInput',
          sectionName: 'applicant',
          props: {
            name: 'phoneNumber',
            type: 'tel',
            tooltip: tooltip(textResource.phoneNumberTooltip),
            label: textResource.phoneNumberLabel,
            placeholder: textResource.phoneNumberPlaceholder,
            hasValidateFunction: true,
            validate: validatePhoneNumberFi,
            lead: true,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'EmailInput',
          sectionName: 'applicant',
          props: {
            name: 'email',
            label: textResource.emailLabel,
            placeholder: textResource.emailPlaceholder,
            tooltip: tooltip(textResource.emailTooltip),
            hasValidateFunction: true,
            validate: email,
            lead: true,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'MonthlyCost',
          props: {
            label: textResource.monthlyCostApproximateMonthlyCostLabel,
            approximateMonthlyCostMobile: textResource.approximateMonthlyCostMobile,
            repaymentTimeLimit: '>5',
            minimal: true,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'Disclaimer',
          props: {
            layout: {
              start: 1,
              end: 7,
            },
          },

        },
        {
          component: 'AcceptNewsLetter',
          form: 'Checkbox',
          props: {
            name: 'acceptNewsletter',
            text: textResource.samblaPlusText,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'Teleporter',
          sectionName: 'applicant',
          props: {
            destinationPath: '/form-page',
            engDestinationPath: '/en/form-page/',
            sendAbandonCartEvent: true,
            label: textResource.nextSlide,
            ctaBtnStyle: true,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'UserAgreementDataPolicy',
          sectionName: 'coApplicant',
          props: {
            userAgreementEng: 'https://www.omalaina.fi/en/user-agreement/',
            userAgreement: 'https://www.new.rahoitu.fi/kayttajaehdot/',
            dataPolicy: 'https://www.new.rahoitu.fi/tietosuojaseloste/',
            dataPolicyEng: 'https://www.omalaina.fi/en/data-protection-policy/',
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'FormBottom',
          props: {
            label: textResource.safeAndSecure,
            trustPilotData: {
              locale: 'fi-FI',
              buData: '5e1470556a6fea0001d29e92',
            },
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
      ],
    },
  ],
};

export default data;
