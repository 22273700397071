/* eslint-disable max-len */
import { feesCost } from '../../../../validators';

import textResource from '../../../../utils/text-resources/rahoitu/FI/messages';
import LOAN_LIMITS from '../../../../localizations/config/FI/shared/loan-limits';
import validateTotalLoan from '../../../../validators/totalLoan';
import { INTEREST } from '../../../../localizations/locale-data';

const tooltip = (text) => {
  const temp = {
    trigger: 'focus',
    text,
    width: 'fill',
    position: 'right',
  };
  return temp;
};

const data = {
  general: {},
  links: {
  },
  slides: [
    {
      showBackButton: false,
      fields: [
        {
          component: 'ValidatedForm',
          form: 'Slider',
          props: {
            label: textResource.applicationLoanAmountLabel,
            showLabel: true,
            name: 'totalAmount',
            showButtons: false,
            type: 'tel',
            lead: true,
            layout: {
              start: 1,
              end: 4,
            },
            sliderConfig: {
              range: {
                min: LOAN_LIMITS.FROM,
                max: LOAN_LIMITS.UP_TO,
              },
              step: LOAN_LIMITS.STEP,
            },
          },
        },
        {
          component: 'StoreValueDisplay',
          props: {
            value: 'totalAmount',
            fontWeight: 'bold',
            fontSize: '1rem',
            noLabel: true,
            fieldDisplay: {
              name: 'totalAmount',
              type: 'tel',
              validate: validateTotalLoan,
              placeholder: textResource.loanCalculatorTotalLoanAmountPlaceholder,
              suffix: textResource.loanCalculatorCurrencySuffix,
              whitelist: true,
              tooltip: tooltip(textResource.loanCalculatorTotalLoanAmountTooltip),
              unstyled: true,
            },
            layout: {
              start: 5,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Slider',
          props: {
            label: textResource.loanCalculatorInterestRateLabel,
            showLabel: true,
            name: 'interestRate',
            showButtons: false,
            type: 'tel',
            lead: true,
            layout: {
              start: 1,
              end: 4,
            },
            sliderConfig: {
              range: {
                min: INTEREST.FROM,
                max: INTEREST.UP_TO,
              },
              step: INTEREST.STEP,
            },
          },
        },
        {
          component: 'StoreValueDisplay',
          props: {
            value: 'interestRate',
            fontWeight: 'bold',
            fontSize: '1rem',
            noLabel: true,
            fieldDisplay: {
              name: 'interestRate',
              type: 'tel',
              placeholder: textResource.loanCalculatorInterestRatePlaceholder,
              suffix: textResource.loanCalculatorInterestRateSuffix,
              whitelist: true,
              tooltip: tooltip(textResource.loanCalculatorInterestRateTooltip),
            },
            layout: {
              start: 5,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Slider',
          props: {
            label: textResource.loanCalculatorRepaymentTimeLabel,
            showLabel: true,
            name: 'repaymentTime',
            showButtons: false,
            type: 'tel',
            lead: true,
            layout: {
              start: 1,
              end: 4,
            },
            sliderConfig: {
              range: {
                min: 1,
                max: 15,
              },
              step: 1,
            },
          },
        },
        {
          component: 'StoreValueDisplay',
          props: {
            value: 'repaymentTime',
            fontWeight: 'bold',
            fontSize: '1rem',
            noLabel: true,
            fieldDisplay: {
              name: 'repaymentTime',
              type: 'tel',
              placeholder: textResource.loanCalculatorRepaymentTimePlaceholder,
              suffix: textResource.loanCalculatorRepaymentTimeSuffix,
              whitelist: true,
              tooltip: tooltip(textResource.loanCalculatorRepaymentTimeTooltip),
            },
            layout: {
              start: 5,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'FeesInput',
          props: {
            name: 'setUpFee',
            isNumber: true,
            label: textResource.loanCalculatorSetUpFeeLabel,
            validate: feesCost,
            placeholder: textResource.loanCalculatorSetUpFeePlaceholder,
            hasValidateFunction: true,
            suffix: textResource.loanCalculatorCurrencySuffix,
            type: 'tel',
            tooltip: tooltip(textResource.loanCalculatorSetUpFeeTooltip),
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'FeesInput',
          props: {
            name: 'aviaFee',
            isNumber: true,
            label: textResource.loanCalculatorAviaFeeLabel,
            validate: feesCost,
            placeholder: textResource.loanCalculatorAviaFeePlaceholder,
            hasValidateFunction: true,
            suffix: textResource.loanCalculatorCurrencySuffix,
            type: 'tel',
            tooltip: tooltip(textResource.loanCalculatorAviaFeeTooltip),
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'SummarySection',
          props: {
            name: 'summarySection',
            loanCalculatorAmortizationTypeLabel: textResource.loanCalculatorAmortizationTypeLabel,
            loanCalculatorAmortizationTypeAnnuity: textResource.loanCalculatorAmortizationTypeAnnuity,
            loanCalculatorAmortizationTypeStraight: textResource.loanCalculatorAmortizationTypeStraight,
            loanCalculatorTotalCostsForLoan: textResource.loanCalculatorTotalCostsForLoan,
            loanCalculatorTotalCostsAndInterestAndFeesForLoan: textResource.loanCalculatorTotalCostsAndInterestAndFeesForLoan,
            loanCalculatorMonthlyCost: textResource.loanCalculatorMonthlyCost,
            loanCalculatorMonthlyCostOverviewButtonText: textResource.loanCalculatorMonthlyCostOverviewButtonText,
            loanCalculatorSummaryTablePageDescription: textResource.loanCalculatorSummaryTablePageDescription,
            loanCalculatorEffectiveInterest: textResource.loanCalculatorEffectiveInterest,
            loanCalculatorSummaryTableColumnMonth: textResource.loanCalculatorSummaryTableColumnMonth,
            loanCalculatorSummaryTableColumnDebtBeforeAmortization: textResource.loanCalculatorSummaryTableColumnDebtBeforeAmortization,
            loanCalculatorSummaryTableColumnAmortization: textResource.loanCalculatorSummaryTableColumnAmortization,
            loanCalculatorSummaryTableColumnInterestCost: textResource.loanCalculatorSummaryTableColumnInterestCost,
            loanCalculatorSummaryTableColumnFeeCost: textResource.loanCalculatorSummaryTableColumnFeeCost,
            loanCalculatorSummaryTableColumnToPay: textResource.loanCalculatorSummaryTableColumnToPay,
            loanCalculatorMonthlyCostMonthOne: textResource.loanCalculatorMonthlyCostMonthOne,
            currencySuffix: textResource.loanCalculatorCurrencySuffix,
            loanCalculatorAmortizationTypeTooltip: tooltip(textResource.loanCalculatorAmortizationTypeTooltip),
            loanCalculatorTotalCostsForLoanTooltip: tooltip(textResource.loanCalculatorTotalCostsForLoanTooltip),
            loanCalculatorTotalCostsAndInterestAndFeesForLoanTooltip: tooltip(textResource.loanCalculatorTotalCostsAndInterestAndFeesForLoanTooltip),
            loanCalculatorMonthlyCostTooltip: tooltip(textResource.loanCalculatorMonthlyCostTooltip),
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'Teleporter',
          props: {
            title: textResource.loanCalculatorNextSlideButtonHeading,
            subtitle: textResource.loanCalculatorNextSlideButtonSubHeading,
            targetSlideIndex: 1,
            label: textResource.loanCalculatorNextSlideButton,
            ctaBtnStyle: true,
            centered: true,
            destinationPath: '/',
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
      ],
    },
  ],
};

export default data;
